<template>
  <v-app-bar app v-if="isLoggedIn" id="header-menu">
    <div class="d-flex align-center header-menu--logo">
      <a href="/">
        <v-img
          alt="SEMP Logo"
          class="nav-logo"
          contain
          src="@/assets/semp-logo.svg"
        />
      </a>
    </div>
    <div class="menu-wrapper">
      <v-row no-gutters>
        <v-spacer />
        <div
          v-if="accessibleRoutes?.multi_site?.view"
          style="margin-right: 5px"
        >
          <div class="pd-left">
            <router-link :to="{ path: '/' }">
              <v-btn
                :ripple="false"
                icon
                title="Multiple Sites Dashboard"
                @click="active = 'multiple-sites-dashboard'"
                :class="{ active: getActive() === 'multiple-sites-dashboard' }"
              >
                <img src="@/assets/menu/menu-multiple-sites.svg" />
              </v-btn>
            </router-link>
          </div>
        </div>
        <div
          v-if="accessibleRoutes?.single_site?.view && ($store.state.siteId || user?.user_site?.length === 1) && $store.getters.isHeaderMenuFull"
          style="margin-right: 5px"
        >
          <div class="pd-left">
            <router-link :to="{ path: `/single-site/${$store.state.siteId}` }">
              <v-btn
                :ripple="false"
                icon
                title="Single Site Dashboard"
                @click="active = 'single-site-dashboard'"
                :class="{ active: getActive() === 'single-site-dashboard' }"
              >
                <img src="@/assets/menu/menu-single-site.svg" />
              </v-btn>
            </router-link>
          </div>
        </div>
        <div
          v-if="accessibleRoutes?.data_analytic?.view && $store.state.siteId && $store.getters.isHeaderMenuFull && allowedUserNames.includes(user.username)"
          style="margin-right: 5px"
        >
          <div class="pd-left">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :ripple="false"
                  icon
                  title="Data Analytic"
                  @click="active = 'analytic'"
                  :class="{ active: getActive() === 'analytic' }"
                  class="get-active"
                  v-bind="attrs"
                  v-on="on"
                >
                  <img src="@/assets/menu/menu-data-analytic.svg" />
                </v-btn>
              </template>
              <v-list
                class="bar-menu-dropdown"
              >
                <v-list-item>
                  <router-link :to="{ path: `/data-analytic/${$store.state.siteId}` }">
                    <div class="menu-dropdown-item">
                      <img src="@/assets/menu/menu-energy.svg" class="menu-dropdown-icon" />Energy
                    </div>
                  </router-link>
                </v-list-item>
                <v-list-item>
                  <router-link :to="{ path: `/data-saving/${$store.state.siteId}` }">
                    <div class="menu-dropdown-item">
                      <img src="@/assets/menu/menu-values.svg" class="menu-dropdown-icon" />Values
                    </div>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <div
          v-if="accessibleRoutes?.asset?.view && $store.state.siteId && $store.getters.isHeaderMenuFull && allowedUserNames.includes(user.username)"
          style="margin-right: 5px"
        >
          <div class="pd-left">
            <router-link :to="{ path: `/asset-management/${$store.state.siteId}` }">
              <v-btn
                :ripple="false"
                icon
                title="Device Management"
                @click="active = 'asset-management'"
                :class="{ active: getActive() === 'asset-management' }"
              >
                <img src="@/assets/menu/menu-device.svg" />
              </v-btn>
            </router-link>
          </div>
        </div>
        <div
          v-if="accessibleRoutes?.alarm?.view && $store.state.siteId && $store.getters.isHeaderMenuFull && allowedUserNames.includes(user.username)"
          style="margin-right: 5px"
        >
          <div class="pd-left">
            <router-link :to="{ path: `/alarm-management/${$store.state.siteId}` }">
              <v-btn
                :ripple="false"
                icon
                title="Alarm Management"
                @click="active = 'alarm-management'"
                :class="{ active: getActive() === 'alarm-management' }"
              >
                <img src="@/assets/menu/menu-alarm.svg" />
              </v-btn>
            </router-link>
          </div>
        </div>
        <div
          v-if="accessibleRoutes?.schedule?.view && $store.state.siteId && $store.getters.isHeaderMenuFull"
          style="margin-right: 5px; pointer-events: none;"
        >
          <div class="pd-left">
            <router-link :to="{ path: '/users/groups' }">
              <v-btn
                :ripple="false"
                icon
                title="Schedule"
                @click="active = 'schedule'"
                :class="{ active: getActive() === 'schedule' }"
              >
                <img src="@/assets/menu/menu-schedule.svg" />
              </v-btn>
            </router-link>
          </div>
        </div>
        <div
          v-if="accessibleRoutes?.inventory?.view && !$store.getters.isHeaderMenuFull && allowedUserNames.includes(user.username)"
          style="margin-right: 5px"
        >
          <div class="pd-left">
            <router-link :to="{ path: '/inventory-management' }">
              <v-btn
                :ripple="false"
                icon
                title="Inventory Management"
                @click="active = 'inventory-management'"
                :class="{ active: getActive() === 'inventory-management' }"
              >
                <img src="@/assets/menu/menu-inventory.svg" />
              </v-btn>
            </router-link>
          </div>
        </div>
        <div
          v-if="accessibleRoutes?.user?.view && !$store.getters.isHeaderMenuFull && !notAllowedUserTypes.includes(user?.user_group?.type)"
          style="margin-right: 5px"
        >
          <div class="pd-left">
            <router-link :to="{ path: '/users/user-management' }">
              <v-btn
                :ripple="false"
                icon
                title="User Management"
                @click="active = 'users'"
                :class="{ active: getActive() === 'users' }"
                class="get-active"
              >
                <img src="@/assets/menu/menu-user.svg" />
              </v-btn>
            </router-link>
          </div>
        </div>
        <div
          style="margin-right: 5px; display: none;"
        >
          <div class="pd-left">
            <router-link :to="{ path: '' }">
              <v-btn
                :ripple="false"
                icon
                :title="`${switchIcon ? 'Light Mode' : 'Dark Mode'}`"
                class="switch-theme-color"
                @click="emitToggleThemeMode"
              >
                <span v-html="getModeIcon()"></span>
              </v-btn>
            </router-link>
          </div>
        </div>
      </v-row>
    </div>
    <div class="d-flex align-items-center current-timestamp-wp">
      <div class="current-timestamp" @dblclick="modalVersion = true">
        <p class="current_time mb-0">{{ currentTime }}</p>
      </div>
      <div class="pd-left">
        <div class="menu-wrapper-mobile">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                type="button"
                v-bind="attrs"
                v-on="on"
                title="Logout"
                @click="modalLogout = true"
              >
                <img src="@/assets/menu/login_icon.svg" />
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="modalLogout"
      max-width="500"
      persistent
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm" class="modal_content">
        <v-card-title class="modal_title">Logout</v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" class="modal_message">
              <div class="text-center">
                <span>Are you sure you want to logout?</span>
              </div>
            </v-col>
            <v-col cols="12" class="close_btn_content">
              <button
                class="btn btn-primary mr-1 btn_black"
                :ripple="false"
                @click="logout"
              >
                YES
              </button>
              <button
                class="btn btn-primary ml-1 btn_black"
                :ripple="false"
                @click="closeModal"
              >
                NO
              </button>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="modalVersion"
      max-width="500"
      persistent
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm" class="modal_content">
        <v-card-title class="modal_title justify-content-center">Smart Energy Management Platform</v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" class="modal_message">
              <div class="text-center">
                <span>Version 0.1.0</span>
              </div>
            </v-col>
            <v-col cols="12" class="close_btn_content">
              <button
                class="btn btn-primary btn_black"
                :ripple="false"
                @click="closeModalVersion"
              >
                OK
              </button>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import { auth } from "@/services";
import moment from "moment";
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "App",
  props: ['switchIcon'],
  data() {
    return {
      active: "multiple-sites-dashboard",
      nowTime: "",
      nowDate: "",
      isBurgerActive: false,
      modalLogout: false,
      modalVersion: false,
      currentTime: '',
      intervalTime: null,
      allowedUserNames: ["Toai01", "victor", "minh", "trieu", "ducnguyen", "binhphung", "kokwai01", "matthew01", "superadmin02", "superadmin2"],
      notAllowedUserTypes: ["site_engineer", "home_user"],
    };
  },
  created() {
    this.fetchDataProfile()
    if(!this.$store.state.siteId) {
      let storeSiteId = localStorage.getItem('selectedSiteId');
      this.$store.commit('setSiteId', storeSiteId);
    }
    this.currentTime = this.getCurrentTimestamp();
  },
  mounted() {
    this.intervalTime = setInterval(() => {
      this.currentTime = this.getCurrentTimestamp();
    }, 1000);
  },
  destroyed() {
    clearInterval(this.intervalTime);
  },
  methods: {
    ...mapActions("common", {
      getProfileUser: "getProfileUser",
    }), 
    async fetchDataProfile() {
       await this.getProfileUser();
    },
    getModeIcon() {
      if (this.switchIcon) {
        return `<i class='fas fa-toggle-on menu-site-icon'></i>`;
      } else {
        return `<i class='fas fa-toggle-off menu-site-icon'></i>`;
      }
    },
    emitToggleThemeMode() {
      const newValue = !this.switchIcon;
      this.$emit('toggle-theme-mode', newValue);
    },
    getCurrentTimestamp() {
      let timeZoneString = localStorage.getItem('selectedTimeZone') ? localStorage.getItem('selectedTimeZone') : null;
      let utcTime = timeZoneString ? moment().utcOffset(timeZoneString) : moment();
      return utcTime.format('ddd DD MMM YYYY | HH:mm:ss (UTCZ)').toUpperCase();
    },
    toggle() {
      this.isBurgerActive = !this.isBurgerActive;
    },
    logout() {
      auth.logout().finally(() => {
        localStorage.removeItem('selectedSiteId');
        localStorage.removeItem('selectedTimeZone')
        // localStorage.removeItem('selectedSiteCurrency')
        localStorage.removeItem('filter_date_type_localstorage');
        localStorage.removeItem('filter_device_type_localstorage');
        localStorage.removeItem('toggle_sites_local_storage');
        localStorage.removeItem('data_weather_one_call_storage');
        localStorage.removeItem('role');
        localStorage.removeItem('access_menu');
        this.$store.dispatch("logout").then(() => window.location.assign("/login"));
      });
    },
    getActive() {
      switch (window.location.pathname) {
        case "/":
          return "multiple-sites-dashboard";
        case "/single-site":
          return "single-site-dashboard";
        case `/data-analytic/${this.$store.state.siteId}`:
        case `/data-saving/${this.$store.state.siteId}`:
          return "analytic";
        case "/asset-management":
          return "asset-management";
        case "/alarm-management":
          return "alarm-management";
        case "/schedule-management":
          return "schedule";
        case "/inventory-management":
          return "inventory-management";
        case "/users/user-management":
        case "/users/user-group-management":
          return "users";
        default:
          return "multiple-sites-dashboard";
      }
    },
    closeModal() {
      this.modalLogout = false;
    },
    closeModalVersion() {
      this.modalVersion = false;
    },
  },
  computed: {
    ...mapGetters("common", {
      getterAccessibleMenus: "getterAccessibleMenus",
      getterRole: "getterRole"
    }),
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    accessibleRoutes: function () {
      return this.$store.getters.accessibleRoutes;
    },
    user: function () {
      return this.$store.getters.user;
    },
  },
};
</script>

<style scoped>
.v-menu__content.menuable__content__active {
  border-radius: 8px;
}
.bar-menu-dropdown {
  background-color: #3A3A3C !important;
  padding: 0;
  min-width: 200px;
}
.bar-menu-dropdown .v-list-item {
  min-height: unset;
  padding: 0;
}

.bar-menu-dropdown .v-list-item a {
  display: inline-block;
  width: 100%;
  padding: 8px 16px;
  color: #FFFFFF;
  text-decoration: none;
}

.bar-menu-dropdown .v-list-item .router-link-exact-active.router-link-active {
  background-color: rgba(0, 0, 0);
}

.bar-menu-dropdown .v-list-item a:hover {
  background-color: #2C2C2E;
}

.bar-menu-dropdown .v-list-item a .menu-dropdown-item {
  display: flex;
  align-items: center;
}

.bar-menu-dropdown .v-list-item a .menu-dropdown-item .menu-dropdown-icon {
  margin-right: 10px;
}
</style>
