<template>
    <footer FooterComp>
        <div class="d-flex justify-content-center footer-content">
            <span>Powered by Panasonic</span>
        </div>
    </footer>
</template>
  
<script>
export default {
    name: "FooterComp",
};
</script>
  
<style scoped>
.footer-content {
    padding-top: 2px;
    padding-bottom: 12px;
}

.footer-content span {
    font-family: 'PUDSansserifM';
    font-size: 15px;
    color: #ffffff;
}

@media only screen and (max-width: 1440px) {
    .footer-content span {
        font-size: 12px;
    }
}
</style>